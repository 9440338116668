<template>
  <div>
    <b-modal
      id="tab"
      ok-only
      ok-title="Save"
      centered
      title="Create Post"
      v-model="isOpen"
      @ok="save"
    >
      <b-form-group label="Title" label-for="h-title" label-cols-md="4">
        <b-form-input
          id="h-title"
          type="text"
          v-model="title"
          placeholder="Title"
        />
      </b-form-group>
      <b-form-group label="Slug" label-for="h-slug" label-cols-md="4">
        <b-form-input
          id="h-slug"
          type="text"
          v-model="slug"
          placeholder="Slug"
        />
      </b-form-group>
      <b-form-group label="Language" label-for="h-language" label-cols-md="4">
        <language-select @selectedLanguage="selectedLanguage" :language="language" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "PostCreateModal",
  data() {
    return {
      isOpen: false,
      title: null,
      slug: null,
      language: "vi",
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    save() {
      this.$emit("createPost", {
        title: this.title,
        slug: this.slug,
        language: this.language,
      });
      this.title = null;
      this.isOpen = false;
      this.slug = null;
      this.language = "vi";
    },
    selectedLanguage(language) {
      this.language = language
    }
  },
};
</script>
