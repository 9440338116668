<template>
  <div>
    <b-button variant="primary" @click="openModal">
      <b-spinner v-if="isLoading" small class="mr-50" />
      <feather-icon v-else icon="PlusIcon" class="mr-50" />
      <span class="align-middle">Add Post</span>
    </b-button>
    <post-create-modal ref="openModalPost" @createPost="create" />
  </div>
</template>

<script>
import PostCreateModal from "./PostCreateModal.vue";
import service from "../../service";

export default {
  components: {
    PostCreateModal,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async create(post) {
      this.isLoading = true;
      let new_post = await service.create(post);
      this.isLoading = false;
      if (new_post) {
        this.$router.push({
          name: "post-detail",
          params: { post_id: new_post._id },
        });
      }
    },
    openModal() {
      this.$refs.openModalPost.openModal();
    },
  },
};
</script>
