<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Post" class="mb-3" />
      </b-col>
      
    </b-row>
    <b-row>
      <b-col md="2">
        <b-form-group label="Language" label-for="language">
          <b-form-select v-model="filter.language" :options="LANGUAGE_OPTIONS" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Search by slug" label-for="slug">
          <b-input v-model="filter.slug" />
        </b-form-group>
      </b-col>
      <b-col md="6 text-right">
        <post-create-form />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <h2 class="mb-2">Post Management</h2>
        <b-row>
          <b-col cols="6" v-for="(post, index) in posts" :key="index">
            <card-post :post="post" />
          </b-col>
        </b-row>
      </section>
    </b-media>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import PostCreateForm from "./_components/PostCreateForm.vue";
import CardPost from "./_components/CardPost.vue";
import service from "../service";
export default {
  components: {
    PostCreateForm,
    CardPost,
  },
  data() {
    return {
      posts: null,
      current_page: 1,
      total_items: 0,
      items_perpage: 90,
      filter: {},
    };
  },
  computed: {
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
    LANGUAGE_OPTIONS(){
      return this.$store.getters['language/language_options']
    }
  },
  watch: {
    current_page() {
      this.getPostList();
    },
    authLanguage() {
      this.getPostList();
    },
    filter:{
      deep: true,
      handler: function(newVal){
        if(newVal){
          this.getPostList();
        }
      }
    }
  },
  created() {
    this.getPostList();
  },
  methods: {
    async getPostList() {
      let response = await service.getPostList({
        query: JSON.stringify(this.filter),
        page: this.current_page,
        limit: this.items_perpage,
      });
      if (response.data.type === "DATA") {
        this.posts = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
  },
};
</script>
