<template>
  <b-card>
    <b-media>
      <template #aside>
        <b-link :to="{ name: 'post-detail', params: { post_id: post._id } }">
          <b-img
            src="@/assets/images/icons/write-note.png"
            width="64"
            alt="placeholder"
          />
        </b-link>
      </template>
      <app-collapse>
        <app-collapse-item :title="''" :is-visible="false">
          <template #header>
            <b-link
              :to="{ name: 'post-detail', params: { post_id: post._id } }"
            >
              {{ post.title }}
            </b-link>
          </template>

          <b-link
            v-for="(page, index) in post.pages"
            :key="index"
            class="d-block p-1 mt-1 shadow rounded"
          >
            <h6>{{ page.title }}</h6>
          </b-link>
        </app-collapse-item>
      </app-collapse>
    </b-media>
    <small class="d-block mt-2" style="color: #bbb">
      Last edited: {{ post.updatedAt | local_time_string }}
    </small>
  </b-card>
</template>

<script>
export default {
  props: {
    post: { type: Object, required: true },
  },
};
</script>
